<template>
<div >
 <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

		<v-card>
				<v-toolbar flat color="white">
                    <v-toolbar-title>Descargar</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    >
                    </v-divider>
                    <v-spacer></v-spacer>
                    
                     <v-menu 
                     v-model="menu"
                      :close-on-content-click="false"
                     >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="rangoFechas"
                                    label="Fechas"
                                    persistent-hint
                                    readonly
                                    append-icon="mdi-calendar-multiple"
                                    v-bind="attrs"
                                    v-on="on"
                                    :clearable="true"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                range
                                v-model="searchFechas"
                                no-title
                                @change="menu = false"
								locale="es-es"
                            >
                            
                            
                            </v-date-picker>
                        </v-menu>
                        
                    
                    <v-select :items="listaEstab"
                    	label="Establecimiento"
                    	dense
                    	item-text="store_name"
          				item-value="product_store_id"
                    	v-model="searchBodega"
                    	append-icon="mdi-magnify"
                    	class='mt-3'
                    	return-object
                    	
                    	solo ></v-select>
                    
                    
    </v-toolbar>
	<v-toolbar flat color="white">
		<v-btn  color="primary" small  class="ma-2 white--text" 
		@click="exportar()"
		>
			<v-icon left >mdi-microsoft-excel</v-icon>
		Exportar
		</v-btn>
	</v-toolbar>
	
	</v-card>
	
</div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'

export default {
	name: "ReporteRecetasPsicotropicos",
	
	data: ()=> ({
    	 
           listaBodegas: [],
           listaEstab: [],
           overlay: false,
           options: {},
           dialog: false,
           notifications: false,
           sound: true,
           widgets: true,
           searchFechas: [],
           searchBodega: {},
           menu: false,
           filters:[
               
               {
                   cols:6,
                   class:'py-0 py-md-2 col-md-2 pr-0 pr-sm-2',
                   v_model:[],
                   label: 'Fecha',
                   type:'input_date',
                   clearable:true,
               },
               {
                   cols:6,
                   class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                   v_model:'',
                   label: 'Bodega',
                   type:'input_select',
                   text:'tipoNombre',
                   value:'tipoId',
                   clearable:true,
                   multiple:true,
                   items:[]
               }]
               
    }),
    computed: {
    	...mapState('master',['loadingTable','user','tenantId']),
    	
    	...mapGetters('access', ['btnAbrirCaja']),
    	 
    	rangoFechas () {
            return this.searchFechas.join(' ~ ')
         },
    },
    methods: {
    	...mapMutations('master',['setUrl','setMenu','setTitleToolbar','setLoadingTable']),
	   	
	    ...mapActions('master',['requestApi','alertNotification']),
	     

        
        exportar() {
        	this.overlay=true
        	
			if(this.searchFechas[0] == null) {
				this.overlay=false
				//alert('Seleccione una fecha');
				this.alertNotification({
                    param:{
                        html: 'Seleccione una fecha.',
                        timer: 10000,
                        title: 'Error',
                        icon: 'mdi-cancel',
                        confirmButtonColor: 'red'
                    }
                });
				
        		return false;
			}   
        	
        	if(this.searchBodega.codigo_establecimiento == null) {
        		this.overlay=false
        		//alert('Seleccione un establecimiento');
        		this.alertNotification({
                    param:{
                        html: 'Seleccione un establecimiento.',
                        timer: 10000,
                        title: 'Error',
                        icon: 'mdi-cancel',
                        confirmButtonColor: 'red'
                    }
                });
        		return false;
        	}
        	
        	this.setUrl('reporte-psicotropicos')
            this.requestApi({
                method : 'POST',
                data : {
                	"fecha_desde": this.searchFechas[0],
                	"fecha_hasta" : this.searchFechas[1],
                	"establecimiento": this.searchBodega.codigo_establecimiento,
                	"bodega" : this.searchBodega.codigo_establecimiento
			    }
            }).then(res=>{
            	//console.log(res.data);			        			    		
            	
            	var a = document.createElement("a");
            	a.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ res.data.datos;
            	a.download = res.data.archivo;
            	a.click();
            	this.overlay=false
            }).then(()=>{
	              
            })
            
        	

        },
        cargarListaBodegas() {
        	this.setUrl('bodega')
            this.requestApi({
                method : 'GET',
                data : {
                	"activas" : "1"			        
			    }
            }).then(res=>{
            				        			    		
            	this.listaBodegas   = res.data._embedded.bodega;
            	            			
            }).then(()=>{
	              
            })
        	
        },
        
        cargarListaEstablecimientos() {
        	this.setUrl('establecimiento')
            this.requestApi({
                method : 'GET',
                data : {
                		        
			    }
            }).then(res=>{
            				        			    		
            	this.listaEstab    = res.data._embedded.establecimiento;
            	            			
            }).then(()=>{
	              
            })
        	
        }
	},
	       

    mounted() {
    	 
    	 this.cargarListaEstablecimientos()
    	 this.setTitleToolbar('REPORTE PSICOTROPICOS')
         
    },
    
    watch: {
    	
    	
    }

    
  }

</script>